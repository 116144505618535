<template>
  <!--begin::Pricing-->
  <div>
    <div>
      <Buttons
        :btnCancel="false"
        :textSave="$t('GENERAL.NEW')"
        v-on:save="newCoupons"
      />
    </div>
    <v-card>
      <v-card-text>
        <v-row gutters="2" justify="end">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row gutters="2" justify="center">
          <v-col cols="12" md="12">
            <v-data-table :headers="headers" :items="Coupons" :search="search">
              <template v-slot:[`item.key`]="{ item }">
                <router-link
                  :to="{
                    name: 'coupons-edit',
                    params: { id: item.couponId },
                  }"
                >
                  {{ item.key }}
                </router-link>
              </template>
              <template v-slot:[`item.start_date`]="{ item }">
                {{ item.start_date | moment("MM/DD/YYYY") }}
              </template>
              <template v-slot:[`item.typeId`]="{ item }">
                {{ labelType[item.typeId - 1] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ALL_COUPONS } from "@/core/services/store/general/coupons.module";
import { mapGetters } from "vuex";
import Buttons from "@/view/content/components/Buttons";

export default {
  components: {
    Buttons,
  },
  data() {
    return {
      search: "",
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t("COUPONS.KEY"),
          align: "start",
          sortable: true,
          value: "key",
        },
        {
          text: this.$t("COUPONS.DISCOUNT_TYPE"),
          value: "typeId",
          sortable: false,
        },
        {
          text: this.$t("COUPONS.DISCOUNT"),
          value: "discount",
          sortable: false,
        },
        {
          text: this.$t("COUPONS.DATE_START"),
          value: "start_date",
          sortable: false,
        },
      ],
      labelType: [this.$t("COUPONS.PERCENTAGE"), this.$t("COUPONS.PERMANENT")],
    };
  },
  created() {
    this.$store.dispatch(GET_ALL_COUPONS);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("COUPONS.TITLE") }]);
  },
  methods: {
    newCoupons() {
      this.$router.push({ name: "coupons-new" });
    },
  },
  computed: {
    ...mapGetters(["Coupons"]),
  },
};
</script>
